import {NavLink, useNavigate} from "react-router-dom";
import "./style.css";
import {useEffect, useState} from "react";
import {Button} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {changeChanel} from "../../store/chanels";
import {getAllChanels, googleLoginFetch, logoutUser, refreshToken, roleById, userProfile} from "../../API/api";
import {useGoogleLogin} from "@react-oauth/google";
import GoogleIcon from '@mui/icons-material/Google';
import LoginIcon from '@mui/icons-material/Login';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import {changeGuest, changeRole} from "../../store/role";
import {changeEmail, changeUserId, changeUserName} from "../../store/profile";

const Header = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { activeChanel } = useSelector((store) => store.chanel)
    const {activeMonth} = useSelector((store) => store.month)
    const { isAdmin } = useSelector((store) => store.isAdmin)
    const { isDirector } = useSelector((store) => store.isDirector)
    const { isOperator } = useSelector((store) => store.isOperator)
    const { isManager } = useSelector((store) => store.isManager)
    const { isGuest } = useSelector((store) => store.isGuest)
    const [userMenu, setUserMenu] = useState(false)

    const [chanels, setChanels] = useState([])
    const getChanels = async () => {
        const data = await getAllChanels()
        setChanels(data)
    }
    useEffect(() => {
        getChanels();
    },[]);

    //change chanel
    const setChanel = (index) => {
        dispatch(changeChanel(index))
        navigate("/")
    }

    // Google Login
    const [loginStatus, setLoginStatus] = useState(false)

    const login = useGoogleLogin({
        onSuccess: async (codeResponse) => {
            // console.log(codeResponse)
            const tokens = await googleLoginFetch(codeResponse.access_token);
            // console.log(tokens)
            if (!tokens) {
                alert("Token null ")
            } else {
                // put login-page logic (i.e. navigating to dashboard page, fetching user from backend
                // using the new access token, etc
                localStorage.setItem("access_token", tokens.accessToken)
                localStorage.setItem("refresh_token", tokens.refreshToken)
                setLoginStatus(true)
                await getProfile(tokens.accessToken)
            }}
        ,
        // flow: 'auth-code',
    });

    // Refresh access token
    const refreshTokens = async () => {
        const tokens = await refreshToken({refreshToken: localStorage.getItem("refresh_token")})
        if (!tokens) {
            localStorage.removeItem("refresh_token")
        }
        if (tokens) {
            localStorage.setItem("access_token", tokens)
            setLoginStatus(true)
            await getProfile(tokens)
        }
        // if (!tokens && localStorage.getItem("refresh_token")) {
        //     localStorage.removeItem("refresh_token")
        // }
    }


    // Распаковка токена
    const parseJwt = (token) => {
        let base64Url = token.split(".")[1];
        let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        let jsonPayload = decodeURIComponent(
            atob(base64)
                .split("")
                .map(function (c) {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join("")
        );
        return JSON.parse(jsonPayload);
    };
    // Проверка токена на Валидность Access
    const tokenCheckDate = (data) => {
        if (data) {
            const token = parseJwt(data)
            let res = true
            if (Date.now() >= new Date((token?.exp - 300) * 1000)) {
                res=false
            }
            return res
        }
    }

    // Получаем профиль пользователя
    const [profiele, setProfile] = useState([])
    const getProfile = async (token) => {
        const accessToken = localStorage.getItem("access_token")
        if (accessToken) {
            const data = await userProfile(token)
            if (data) {
                setProfile(data)
                // let roles = []
                // data?.roles?.map((el) => {
                //     roles.push(el.value)
                // })
                // const role = await roleById(accessToken, data.role)
                dispatch(changeRole(data.role))
                dispatch(changeUserName(data.name))
                dispatch(changeEmail(data.email))
                dispatch(changeUserId(data.id))
            }
        }
    }

    useEffect(() => {
        const accessToken = localStorage.getItem("access_token")
        const refreshToken = localStorage.getItem("refresh_token")
        if (!accessToken && refreshToken) {
            refreshTokens()
        }
        // console.log(tokenCheckDate(accessToken))
        if (accessToken && refreshToken) {
            const chk = tokenCheckDate(accessToken)
            if (chk) {
                setLoginStatus(true)
                getProfile(accessToken)
            } else {
                localStorage.removeItem("access_token")
                const refresh = refreshTokens()
                if (!refresh) {
                    setLoginStatus(false)
                }
            }
        }
        if (refreshToken) {
            setInterval(() => refreshTokens(), 3000000)
        }
    }, [activeChanel, activeMonth])

    // Выход из пользователя
    const logout = async () => {
        await logoutUser({refreshToken: localStorage.getItem("refresh_token")})
        localStorage.removeItem("access_token")
        localStorage.removeItem("refresh_token")
        alert(`Вы успешно вышли с пользователя ${profiele.email}!`)
        setUserMenu(false)
        setLoginStatus(false)
        dispatch(changeGuest(true))
        navigate("/")
    }

    // console.log(activeChanel)
    return (
        <header>
            <nav>
                <NavLink  className={({ isActive }) => (isActive ? 'active-link' : '')} to="/">Главная</NavLink>
                {!isGuest ?
                    <>
                        <NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="/mp">Медиапланы</NavLink>
                        <NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="/schedule">Расписание</NavLink>
                        <NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="/banner">Баннера</NavLink>
                        <NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="/ticker">Бегушка</NavLink>
                        <NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="/watch">Просмотр</NavLink>
                        {/*<NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="/excel-parse">ЕксельПарс</NavLink>*/}
                        {/*isOperator || isAdmin ?
                            <NavLink className={({ isActive }) => (isActive ? 'active-link' : '')} to="/settings">Настройки</NavLink> : ""*/}
                    </>
                    :
                    ""
                }
            </nav>
            <div className="changeChanel">
                {chanels.map((chanel, index) => {
                    if (chanel.active) {
                        return (
                            <Button key={chanel.id} variant={Number(activeChanel) === index ? "contained" : "outlined"}
                                    onClick={() => setChanel(index)}
                                    className={Number(activeChanel) === index ? "" : "notActiveBtn"}>
                                {chanel.chanel}
                            </Button>)
                    }
                })}
                {loginStatus ?
                    <div className="googlelogin-btn" variant="outlined" onClick={() => setUserMenu(!userMenu)}>
                        <AdminPanelSettingsIcon/>
                    </div>
                    :
                    <div className="googlelogin-btn" variant="outlined" onClick={() => login()}>
                        {/*Вход&nbsp;*/}<GoogleIcon/><LoginIcon/>
                    </div>
                }
                <div className={`user-menu`} style={userMenu ? {display: "flex"} : {}}>
                    <div className="user-menu-item">
                        <p>Добро пожаловать:</p>
                        <span>{profiele.name}</span>
                    </div>
                    <div className="user-menu-item">
                        <p>Группа:</p>
                        {
                            (isAdmin && <span>Администратор</span>) ||
                            (isDirector && <span>Директор</span>) ||
                            (isOperator && <span>Оператор</span>) ||
                            (isManager && <span>Мэнеджер</span>) ||
                            (isGuest && <span>Гость</span>)
                        }
                    </div>
                    {(isAdmin || isDirector) &&
                        <div
                            className="googlelogin-btn"
                            variant="outlined"
                            onClick={() => navigate("/users-settings")}
                        >Пользователи</div>
                    }
                    {(isAdmin | isOperator) &&
                        <div
                            className="googlelogin-btn"
                            variant="outlined"
                            onClick={() => navigate("/settings")}
                        >Настройки телеканала</div>}
                    <div className="user-menu-item"></div>
                    <p style={{fontStyle: "italic"}}>Все каналы:</p>
                    {chanels.map((chanel, index) => {
                        if (Number(activeChanel) === index) {
                            return (
                                <Button key={chanel.id} variant="contained"
                                        onClick={() => setChanel(index)}
                                >
                                    {chanel.chanel}
                                </Button>)
                        } else {
                            return (
                                <Button key={chanel.id} variant="outlined" style={{background: "white"}}
                                        onClick={() => setChanel(index)}
                                >
                                    {chanel.chanel}
                                </Button>)
                        }
                    })}
                    <div className="user-menu-item"></div>
                    <div className="googlelogin-btn" variant="outlined" onClick={() => logout()}>Выход</div>
                </div>
            </div>

        </header>
)
}

export default Header